.pipeTools > div:first-child {
  margin-bottom: 1rem;
}

.toolBar {
  gap: 1rem;
  margin-bottom: 1rem;
}

.sortIcon {
  fill: var(--subdued-color);
}

.toolItem {
  display: flex;
  gap: 0.1rem;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
  }
}

.filterTool {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.filterTool :global(.p-component.p-selectbutton) :global(.p-button) {
  font-size: 0.6rem;
  padding: 0.5rem 0.75rem;

  @media screen and (min-device-width: 768px) {
    font-size: 0.85rem;
  }
}

.searchTool :global(.p-inputtext) {
  width: 10rem;
}
