.cigarEditForm {
  [id="brand"],
  [id="maker"],
  [id="binder"],
  [id="wrapper"],
  [id="filler"],
  [id="flavorings"],
  [id="name"] {
    width: 100%;
  }

  [id="origin"] {
    width: 10rem;
  }

  [id="length"] input,
  [id="ring"] input {
    width: 10rem;
  }
}
