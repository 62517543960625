.activityCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid var(--background-popover);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;

  header {
    a {
      color: var(--secondary-color);
      font-weight: bolder;
    }
  }
}

.info {
  width: 100%;
}

.icon {
  margin-right: 1rem;
}

.when {
  margin-bottom: 0.25rem;
  color: var(--primary-color);
  font-weight: 600;
}

.body {
  line-height: 125%;
  margin-bottom: 0.5rem;
}

.spaced {
  margin-bottom: 0.5rem;
}

.ratings {
  margin-top: 0.5rem;

  > div {
    margin-bottom: 0.25rem;
  }
}

.subject {
  margin-bottom: 0.5rem;
  width: 100%;
}

.tags {
  margin-top: 0.5rem;

  :global(.p-tag-value) + span {
    padding: 0 1rem 0 1rem;
  }
}

// TODO: make individual modules for specific cards?
.cigarImage {
  margin: 1rem 0 1rem 0;
}

.blendImageBar {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
  width: 100%;

  .labelLink {
    width: 40%;
  }

  .label {
    position: relative;
    aspect-ratio: 1/1;
  }

  .pipeLink {
    width: 40%;
  }
}

.weighted {
  font-weight: 400;
  font-size: 75%;
}
