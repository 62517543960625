.pipeEditForm {
  [id="maker"],
  [id="model"],
  [id="source"],
  [id="markingsLeft"],
  [id="markingsRight"],
  [id="markingsBottom"],
  [id="markingsOther"],
  [id="extra"] {
    width: 100%;
  }

  [id="country"] {
    width: 10rem;
  }

  [id="length"] input,
  [id="height"] input,
  [id="outerDiameter"] input,
  [id="chamberDiameter"] input,
  [id="depth"] input,
  [id="weight"] input,
  [id="price"] input {
    width: 10rem;
  }
}
