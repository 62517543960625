.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
}

.info {
  text-align: center;
}

.info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info li span {
  cursor: pointer;
}

.info li :global(.anticon) {
  margin-left: 0.5rem;
}

.version {
  color: var(--primary-color);
  margin-top: 0.5rem;
}

.upload {
  margin-right: 2rem;
}
