.uploader {
  padding: 1rem;

  :global(.p-fileupload-buttonbar) {
    justify-content: center;

    :global(.p-button) {
      font-size: 0.75rem;

      .pi {
        font-size: 0.75rem;
      }
    }
  }
}

.target {
  margin-bottom: 1rem;
}

.zone {
  font-size: 4rem;
  color: var(--text-subdued);
}

.subject {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  label {
    color: var(--primary-color);
  }
}
